import uploadIndex from './uploadIndex.md';
import uploadsUpdate from './uploadsUpdate.md';
import uploads from './uploads.md';

const upload = [
  uploadIndex,
  uploadsUpdate,
  uploads
];

export default upload;