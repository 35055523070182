import img1 from './getting-started-1.png';
import img2 from './getting-started-2.png';
import img3 from './getting-started-3.png';
import img4 from './getting-started-4.png';
import img5 from './getting-started-5.png';
import img6 from './getting-started-6.png';
import img7 from './getting-started-7.png';
import img8 from './getting-started-8.png';
import img9 from './getting-started-9.png';
import img10 from './getting-started-10.png';

const images = {
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10
};

export default images;